@import "../../assets/styles/variables.scss";

.progressSteps {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 84px;
    // padding: 0 20px;

    .step {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 40px;
        border-radius: 20px;
        background-color: #f0f0f0;
        color: #888;
        font-size: 14px;
        font-weight: 600;
        transition:
            background-color 0.3s,
            color 0.3s;
        cursor: pointer;
        user-select: none;

        &.activeStep {
            background-color: white;
            color: black;
            border: 2px solid black;
        }
    }

    .stepDivider {
        display: flex;
        align-items: center;
        color: #E2E2ED;
        margin: 0 4px;
        user-select: none;
        white-space: nowrap;
        font-size: 10px;
    }
}

.indicatorContainer {
    padding: 0;
}

.mobileSelect {
    color: black;
    font-weight: 600;
}

.approvalIndicator {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 16px 0 20px;
}

.approvalText {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 14px;
    color: #333;

    .probabilityValue {
        color: #71BE81;
        font-weight: 600;
        margin-left: 4px;
    }
}

.progressBarContainer {
    flex: 1;
    min-width: 0;
}

.progressBar {
    width: 100%;
    height: 8px;
    background-color: #E0E0E0;
    border-radius: 4px;
}

.progressFill {
    width: 0;
    height: 100%;
    background-color: #71BE81;
    border-radius: 4px;
    transition: width 0.3s ease;
}

.educationBlock {
    width: fit-content;
    max-width: 300px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 14px;
    gap: 8px;
    background-color: #F7F7F7;
    border-radius: 32px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        width: 100%;
        max-width: unset;
        justify-content: unset;
    }
}