.container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding: 20px;
    gap: 20px;

    @media (min-width: 768px) {
        align-items: center;
    }

    h1 {
        color: #333;
    }

    @media (max-width: 768px) {
        text-align: left;
    }
}

.downloadButton {
    margin-top: 16px;
    width: fit-content;
}

.content {
    width: 100%;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #fff;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 24px;

    h2 {
        color: #333;
        margin-bottom: 20px;
        font-size: 22px;
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.field {
    strong {
        color: #666;
        margin-right: 8px;
    }
}

.fieldName {
    color: #AAAABB;
    margin-bottom: 5px;
}

.fieldData {
    font-size: 18px;
    font-weight: 600;
}

.errorContainer {
    text-align: center;
    padding: 40px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h2 {
        color: #ff4444;
        margin-bottom: 16px;
    }

    p {
        color: #666;
    }
}

.approveText {
    line-height: 1.5;
}