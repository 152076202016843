.cardWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 384px;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 16px;
    gap: 12px;
}

.successBorder {
    border: 1px solid #4CAF50;
}

.title {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    color: #333333;
    margin: 0;
}

.status {
    font-size: 14px;
    line-height: 20px;
    color: #8E8E93;
}

.approveWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.approveIcon {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4CAF50;
    color: white;
    font-size: 18px;
    border-radius: 50%;
}

.approveText {
    font-size: 16px;
    line-height: 22px;
    color: #4CAF50;
    font-weight: 500;
}

.documentStatus {
    font-size: 14px;
    line-height: 20px;
    color: #6E6E73;
    text-align: center;
}

.hint {
    font-size: 14px;
    line-height: 20px;
    color: #8E8E93;
    text-align: center;
    margin-top: 8px;

    &.success {
        color: #4CAF50;
    }

    &.error {
        color: #FF3B30;
    }
}

.confirmButton {
    padding: 12px 24px;
    background: #007AFF;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background: #0056b3;
    }

    &:disabled {
        background: #D1D1D6;
        cursor: not-allowed;
    }
}

.loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #8E8E93;
    text-align: center;
}

.statusWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    padding: 12px;
    background: #FFE5E5;
    border-radius: 24px;
    text-align: center;
    color: #FF3B30;
}

.statusIcon {
    width: 20px;
    height: 20px;
}

.rotating {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.confirmSection {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    padding: 12px;
    background: #ebf5e3;
    border-radius: 24px;
    text-align: center;
    color: green;
}

.confirmButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    gap: 32px;
}

.confirmText {
    padding: 8px 16px;
    background: #F5F5F5;
    border-radius: 24px;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
}

.confirmDescription {
    color: #AAAABB;
    font-size: 15px;
}