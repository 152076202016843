.counterContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 208px;
    height: 64px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    // padding: 10px;
    // background-color: #fafafa;

    button {
        width: 64px;
        height: 100%;
        background-color: #f7f7f7;
        border: none;
        border-radius: 12px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #9e9e9e;

        &:disabled {
            color: #cfcfcf;
        }
    }

    .counterValue {
        font-size: 24px;
        color: #9e9e9e;
    }

    .decrementButton {
        margin-right: 10px;
    }

    .incrementButton {
        margin-left: 10px;
    }
}

.counterLabel {
    font-size: 14px;
    color: #AAAABB;
    text-transform: uppercase;
    padding-bottom: 8px;
}